import Swal from "sweetalert2";

require('script-loader!slick-carousel');

$(document).ready(function() {

    // promo carousel section
    var setActiveCls = function(pos) {
        $('.promo .promo-slide-indicators li.active').removeClass("active");
        $('.promo .promo-slide-indicators li').eq(pos).addClass("active");
    };

    var underAttackFormSubmited = false;
    $("a[href='?underAttackPopup']").add($("a[href='/?underAttackPopup']")).click(function(){
        var htmlContent = $('#underAttackPopupContent').val();
        Swal.fire({
            html: htmlContent,
            showCloseButton: true,
            showConfirmButton: false,
            showCancelButton: false,
            customClass: {
                container: 'under-attack-popup'
            },
            showClass: {
                popup: 'animated fadeInDown faster',
                icon: 'animated heartBeat delay-1s'
            },
            hideClass: {
                popup: 'animated zoomOut faster',
            },
            willOpen: () => {
                grecaptcha.render('underAttackRecaptcha');

                $(".under-attack-form").submit(function(e) {
                    e.preventDefault();
                    try {
                        window.dataLayer && Array.isArray(window.dataLayer) && window.dataLayer.push({'event': 'Form_ThankYouPage_UnderAttack'});
                        window.ymid && typeof window.ymid === 'function' && window.ymid('reachGoal', 'Form_ThankYouPage_UnderAttack');
                    } catch (e) {
                    }
                    if (underAttackFormSubmited) {
                        return;
                    }
                    underAttackFormSubmited = true;
                    var form = $(this);
                    var url = form.attr('action');
                    $('.validation-errors').hide();
                    $.ajax({
                        url: url,
                        type: 'post',
                        data: form.serialize(),
                        dataType: 'text',
                        success: function(data)
                        {
                            data = JSON.parse(data);
                            if (data.result == 'ok') {
                                Swal.fire({
                                    icon: 'success',
                                    html: '<h5>' + data.resultText + '</h5>',
                                    showConfirmButton: true,
                                    customClass: {
                                        container: 'under-attack-popup-result'
                                    }
                                })
                            } else {
                                $('.validation-errors ul').empty();
                                console.log(data);
                                $.each( data.invalidFields, function( key, value ) {
                                    console.log(value);
                                    $('.validation-errors ul').append('<li>'+value+'</li>')
                                });
                                $('.validation-errors').show();
                                grecaptcha.reset();
                            }
                            underAttackFormSubmited = false;
                        },
                        error: function (data) {
                            Swal.fire({
                                icon: 'error',
                                html: '<h5>' + $('#underAttackFormError').val() + '</h5>',
                                showConfirmButton: true,
                                customClass: {
                                    container: 'under-attack-popup-result'
                                }
                            })
                            underAttackFormSubmited = false;
                        }
                    });
                });
            }
        });
        return false;
    });

    $('.promo .promo-carousel').on('init', function() {
        $('.promo').removeClass('inactive');
    });

    $('.promo .promo-carousel').slick({
        autoplay: true,
        autoplaySpeed: 6000,
        arrows: false,
        draggable: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
        // variableWidth: true,
        // variableHeight: true
    }).on('beforeChange', function(event, slick, currentSlide, nextSlide){
        setActiveCls(nextSlide);
    }).on('afterChange', function(event, slick, currentSlide, nextSlide){
        document.documentElement.dispatchEvent(new Event("carousel_changed"));
    });

    $('.promo .promo-slide-indicators li')
      .on("click", function (e)
      {
          if (document.body.offsetWidth > 992) {
              e.preventDefault();
              $('.promo .promo-carousel').slick("slickGoTo", $(this).data('slide-to'));
          }
      });

    $('.success-story-carousel').on('init', function(slick) {
        $("[data-match-height=sucess-story-homepage]").matchHeight();
    });

    $('.success-story-carousel').slick({
        autoplay: true,
        autoplaySpeed: 6000,
        arrows: true,
        draggable: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
        // variableWidth: true,
        variableHeight: true
    });
})
